<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <loading v-if="cargando" />
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="mb-0">Comercialización</h5>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Comercialización</li>
                <li class="breadcrumb-item active">General</li>
                <li class="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="fecha_final">filtrar</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="fecha_final"
                  v-model="filters.fecha"
                  @input="formatDate"
                  v-mask="{
                    mask: '9999/99',
                  }"
                  :class="isValidDate ? 'is-valid' : 'is-invalid'"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <button
                type="button"
                @click="getDatos()"
                class="btn alert-default-info mt-4"
                v-if="isValidDate"
              >
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div class="container-fluid">
        <div class="row"></div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-chart">
              <div class="card-header bg-gray-dark">
                <h3 class="card-title text-white">
                  <i class="fas fa-file-invoice-dollar"></i>
                  <b> Ventas Cliente</b>
                </h3>
              </div>
              <div class="card-body">
                <ComGraficaVentasClientes ref="ComGraficaVentasClientes" />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row"></div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-chart">
              <div class="card-header bg-gray-dark">
                <h3 class="card-title text-white">
                  <i class="fas fa-file-invoice-dollar"></i>
                  <b> Promedio Brent</b>
                </h3>
              </div>
              <div class="card-body">
                <ComGraficaBrent ref="ComGraficaBrent" />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row"></div>
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-chart">
              <div class="card-header bg-gray-dark">
                <h3 class="card-title text-white">
                  <i class="fas fa-file-invoice-dollar"></i>
                  <b> Cumplimiento Asignado</b>
                </h3>
              </div>
              <div class="card-body">
                <ComGraficaCumplimientoAsignado
                  ref="ComGraficaCumplimientoAsignado"
                />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-chart">
              <div class="card-header bg-gray-dark">
                <h3 class="card-title text-white">
                  <i class="fas fa-file-invoice-dollar"></i>
                  <b> Cumplimiento Postulado</b>
                </h3>
              </div>
              <div class="card-body">
                <ComGraficaCumplimientoPostulado
                  ref="ComGraficaCumplimientoPostulado"
                />
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "../../../components/Loading";
import { helpers } from "vuelidate/lib/validators";
import moment from "moment";
import ComGraficaCumplimientoPostulado from "./ComGraficaCumplimientoPostulado";
import ComGraficaVentasClientes from "./ComGraficaVentasClientes";
import ComGraficaCumplimientoAsignado from "./ComGraficaCumplimientoAsignado";
import ComGraficaBrent from "./ComGraficaBrent";
const monthFormat = helpers.regex(
  "monthFormat",
  /^(19\d\d|20\d\d|3000)\/(0[1-9]|1[0-2])$/
);

export default {
  name: "ComDashboardIndex",
  components: {
    ComGraficaVentasClientes,
    ComGraficaCumplimientoPostulado,
    ComGraficaCumplimientoAsignado,
    ComGraficaBrent,
    Loading,
  },
  data() {
    return {
      cargando: false,
      filters: {
        fecha: "",
        mes_actual: true,
      },
      isValidDate: true,
      search: "",
      hoy: moment().format("YYYY-MM"),
    };
  },

  methods: {
    async getIndex() {
      
      this.filters.fecha = await this.hoy;
      await this.$refs.ComGraficaVentasClientes.getGrafica();
      await this.$refs.ComGraficaCumplimientoAsignado.getGrafica();
      await this.$refs.ComGraficaCumplimientoPostulado.getGrafica();
      await this.$refs.ComGraficaBrent.getGrafica();
      
    },

    formatDate(event) {
      const inputValue = event.target.value;
      const strippedValue = inputValue.replace(/[^\d]/g, "");

      if (/^\d{6}$/.test(strippedValue)) {
        const year = strippedValue.substring(0, 4);
        const month = strippedValue.substring(4, 6);

        if (month > moment().month() + 1 || year > moment().year()) {
          this.$swal({
            icon: "error",
            title: `La fecha filtrada no puede ser mayor a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.isValidDate = false;
        } else {
          this.filters.fecha = `${year}-${month}`;
          this.validaFechas();
          this.isValidDate = true;
        }
      } else {
        this.isValidDate = false;
      }
    },

    async getDatos() {
      this.filters.mes_actual = true;
      if (this.hoy != moment(this.filters.fecha).format("YYYY-MM")) {
        this.filters.mes_actual = null;
      }

      await this.$refs.ComGraficaVentasClientes.getGrafica();
      await this.$refs.ComGraficaCumplimientoAsignado.getGrafica();
      await this.$refs.ComGraficaCumplimientoPostulado.getGrafica();
      await this.$refs.ComGraficaBrent.getGrafica();

    },

    validaFechas() {
      const fecha_menor = this.filters.fecha;
      const fecha_mayor = this.hoy;
      if (fecha_menor > fecha_mayor) {
        this.$swal({
          icon: "error",
          title: `La fecha filtrada no puede ser mayor a la fecha actual...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
  },

  async mounted() {
    await this.getIndex();
  },
};
</script>
