<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

export default {
  name: "ComGraficaPromedioBrent",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        xAxis: {
          categories: [],
        },

        yAxis: {
          title: {
            text: "PROMEDIO",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "PROMEDIO BRENT MENSUAL",
          align: "center",
        },

        tooltip: {
          valueSuffix: " USD",
        },
        plotOptions: {
          series: {
            borderRadius: "25%",
          },
        },

        series: [
          {
            type: "column",
            data: ["24, 79, 72, 240, 167"],
          },
          {
            type: "pie",
            name: "Brent",
            data: [
              {
                name: "Promedio Brent",
                color: "#86bad8",
                y: 11,
                dataLabels: {
                  enabled: true,
                  distance: -28,
                  format: "",
                  style: {
                    fontSize: "11px",
                    color: "black",
                  },
                },
              },
            ],
            center: [0, 0],
            size: 50,
            innerSize: "65%",
            showInLegend: false,
            dataLabels: {
              enabled: false,
            },
          },
          {
            type: "area",
            name: "Brent Diario",
            data: [],
            marker: {
              lineWidth: 1,
              lineColor: "white",
              fillColor: "blue",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#201747"],
                [1, "#45589c"],
              ],
            },
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/com/dashboardCom/promedioBrent", {
          params: this.$parent.filters,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaTurnosFinalizados(me.respuesta);
        });
    },

    async graficaTurnosFinalizados(resp) {
      this.chartOptions.xAxis.categories = resp["fechas"].reverse();
      this.chartOptions.series[1].data[0].y = resp["promedio"];
      this.chartOptions.series[2].data = resp["precios"]
        .map((precio) => parseFloat(precio))
        .reverse();
      this.chartOptions.series[1].data[0].dataLabels.format = resp["promedio"]
        .toFixed(2)
        .toString();
      this.chartOptions.series[0].name = resp["mes"];
    },
  },
};
</script>
