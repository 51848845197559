<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

export default {
  name: "ComGraficaCumplimientoAsignado",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
        },

        xAxis: {
          categories: [],
        },

        tooltip: {
          format:
            "<b>{key}</b><br/>{series.name}: {y}<br/>" +
            "Total: {point.stackTotal}",
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },

        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "PORCENTAJE CUMPLIMIENTO",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "% Cumplimiento Asignado VS Cargado",
          align: "left",
        },
        subtitle: {
          text: "CLIENTE CUMPLIMIENTO",
          align: "center",
        },

        series: [
          {
            name: "",
            data: [],
            stack: "",
          },
        ],
        credits: {
          enabled: false,
        },
      },
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/com/dashboardCom/cumplimientoAsignado", {
          params: this.$parent.filters,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaTurnosFinalizados(me.respuesta);
        });
    },

    async graficaTurnosFinalizados(resp) {
      let nProductoPromedioMap = {};
      let cliente = [];
      for (let i = 0; i < this.respuesta.length; i++) {
        if (
          this.respuesta[i].nCliente != 0 &&
          this.respuesta[i].nCliente != null
        ) {
          cliente.push(this.respuesta[i].nCliente);
          let productos = this.respuesta[i]["productos"];
          productos.forEach((element) => {
            const nProducto = element["nProducto"];
            const promedio = parseFloat(element["promedio"]);

            if (!nProductoPromedioMap[nProducto]) {
              nProductoPromedioMap[nProducto] = [];
            }
            nProductoPromedioMap[nProducto].push(promedio);
          });
        }
      }
      this.chartOptions.xAxis.categories = cliente;
      const nProductos = Object.keys(nProductoPromedioMap);
      const seriesData = nProductos.map((nProducto) => {
        return {
          name: nProducto,
          data: nProductoPromedioMap[nProducto],
          stack: nProducto,
        };
      });

      this.chartOptions.series = seriesData;
    },
  },
};
</script>
